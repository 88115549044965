import {
  COMMITTEE_APPOINTMENTS,
  NEW_COMMITTEE_APPOINTMENT,
  UPDATE_COMMITTEE_APPOINTMENT,
  DELETE_COMMITTEE_APPOINTMENT
} from "../../actions/types";

const initialState = {
  items: [],
  item: {},
  itemUpdated: {},
  itemDel: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case COMMITTEE_APPOINTMENTS:
      return {
        ...state,
        items: action.payload
      };

    case NEW_COMMITTEE_APPOINTMENT:
      return {
        ...state,
        item: action.payload
      };

    case UPDATE_COMMITTEE_APPOINTMENT:
      return {
        ...state,
        itemUpdated: action.payload
      };

    case DELETE_COMMITTEE_APPOINTMENT:
      return {
        ...state,
        itemDel: action.payload
      };

    default:
      return state;
  }
}
