import { FETCH_POSTS, NEW_POST, UPDATE_POST, DELETE_POST, FETCH_MEMBERS } from "../../actions/types";

const initialState = {
  items: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_POSTS:
      return {
        ...state,
        items: action.payload
      };

    default:
      return state;
  }
}
