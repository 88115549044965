import { AUTH_LOGIN, AUTH_LOGOUT } from "../../actions/types";
import isEmpty from 'lodash/isEmpty'

const initialState = {
    userData: [],
    user_token: '',
    isAuthenticated: false
};

export default function(state = initialState, action) {
  
    switch (action.type) {
        case AUTH_LOGIN:
          return {
            ...state, // Preserve states that are unchanged
            userData: action.payload['data'], // Values to be changed
            user_token: action.payload['token'],
            isAuthenticated: !isEmpty(action.payload),
        };
      
        default:
          return state;
      }
      console.log(action.payload);
    };
  