import {
  FETCH_DOCUMENTS,
  NEW_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT
} from "../../actions/types";

const initialState = {
  items: [],
  item: {},
  itemUpdated: {},
  itemDel: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DOCUMENTS:
      return {
        ...state,
        items: action.payload
      };

    case NEW_DOCUMENT:
      return {
        ...state,
        item: action.payload
      };

    case UPDATE_DOCUMENT:
      return {
        ...state,
        itemUpdated: action.payload
      };

    case DELETE_DOCUMENT:
      return {
        ...state,
        itemDel: action.payload
      };

    default:
      return state;
  }
}
