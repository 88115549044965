import propTypes from "prop-types";
import { connect } from "react-redux";


class Auth {

    constructor() {
        this.authinticated = true;
    }

    isAuthinticated(cb) {
        //if(this.props.token != null && this.props.token.length > 1) {
            return true;
        //}else{
            return false;
        // }
        // return true;
    }


}



// Auth.propTypes = {
//     //FUCTIONS REQUIRED
  
//     //ARRAYS REQUIRED
//     token: propTypes.object.isRequired
    
//   };
  
//   const mapStateToProps = state => ({
//     token: state.token.userData,
//   });
  
//   export default connect(
//     mapStateToProps,
//     { }
//   )(Auth);
//
export default new Auth();