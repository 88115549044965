import { FETCH_POSTS, FETCH_MEMBERS, FETCH_MEMBERS1} from "../../actions/fars/typefars";

const initialState = {
  submitted: [],
  progressing:[],
  not_submitted:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_POSTS:
      return {
        ...state,
        submitted: action.payload
      };

    case FETCH_MEMBERS:
      return {
        ...state,
        progressing: action.payload
      };

    case FETCH_MEMBERS1:
      return {
        ...state,
        not_submitted: action.payload
      };

      
    default:
      return state;
  }
}
