import { FETCH_REGIONS, NEW_REGION, UPDATE_REGION, DELETE_REGION } from "../../actions/types";

const initialState = {
  items: [],
  item: {},
  itemUpdated:{},
  itemDel:{},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_REGIONS:
      return {
        ...state,
        items: action.payload
      };

    case NEW_REGION:
      return {
        ...state,
        item: action.payload
      };

    case UPDATE_REGION:
      return {
        ...state,
        itemUpdated: action.payload
      };

    case DELETE_REGION:
      return {
        ...state,
        itemDel: action.payload
      };

    default:
      return state;
  }
}
