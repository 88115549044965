import {
  FETCH_COMMITTEE_MEMBERS,
  NEW_COMMITTEE_MEMBER,
  UPDATE_COMMITTEE_MEMBER,
  DELETE_COMMITTEE_MEMBER
} from "../../actions/types";

const initialState = {
  items: [],
  item: {},
  itemUpdated: {},
  itemDel: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_COMMITTEE_MEMBERS:
      return {
        ...state,
        items: action.payload
      };

    case NEW_COMMITTEE_MEMBER:
      return {
        ...state,
        item: action.payload
      };

    case UPDATE_COMMITTEE_MEMBER:
      return {
        ...state,
        itemUpdated: action.payload
      };

    case DELETE_COMMITTEE_MEMBER:
      return {
        ...state,
        itemDel: action.payload
      };

    default:
      return state;
  }
}
