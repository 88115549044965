import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import IdleTimer from 'react-idle-timer'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../store/actions/actions";
import ToggleFullscreen from "../Common/ToggleFullscreen";
import HeaderRun from "./Header.run";
import axios from "axios";
import { PISC_URL, SETTING_URL, USERS_URL } from "../constant";
import TimeOut from "./TimeOut";

class Header extends Component {
  constructor(props) { 
    super(props)
    this.state = {
      redirect:false,
      templates: [],
      quarterly_template: []
    }
  }

  async componentDidMount() {
  
    HeaderRun();

    console.log(this.props.token[0])
    if(this.props.token === null || this.props.token.length < 1 || this.props.isAuthenticated == false) {
      this.setState({
        redirect:true
      })
    }

    this.getTemplates();
    this.getQuarterlyReport();
  }

  toggleUserblock = e => {
    e.preventDefault();
    this.props.actions.toggleSetting("showUserBlock");
  };

  toggleOffsidebar = e => {
    e.preventDefault();
    this.props.actions.toggleSetting("offsidebarOpen");
  };

  toggleCollapsed = e => {
    e.preventDefault();
    this.props.actions.toggleSetting("isCollapsed");
    this.resize();
  };

  toggleAside = e => {
    e.preventDefault();
    this.props.actions.toggleSetting("asideToggled");
  };

  resize() {
    // all IE friendly dispatchEvent
    var evt = document.createEvent("UIEvents");
    evt.initUIEvent("resize", true, false, window, 0);
    window.dispatchEvent(evt);
    // modern dispatchEvent way
    // window.dispatchEvent(new Event('resize'));
  }

  getTemplates = async() => {
    let payload;
   try{ 
    if(this.props.token[0].user_pisc_id > 1){
      payload = {
        list_type: "PSC",
        id: this.props.token[0].user_pisc_id
      }
    }else{
      payload = {
        list_type: "All"
      }
    }}catch(e){

    console.log("Something went wrong,e");
    }

    console.log(payload);
    await axios.post(SETTING_URL + "/api/reportingTemplate/show", payload)
      .then(response => {
        console.log(response.data);
        this.setState({
          templates: response.data,
        });
        console.log(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  getQuarterlyReport = async() => {
    
    let payload = {
      list_type: "Quarterly"
    }

    console.log(payload);
    await axios.post(SETTING_URL + "/api/reportingTemplate/show", payload)
      .then(response => {
        console.log(response.data);
        this.setState({
          quarterly_template: response.data,
        });
        console.log(response.data);
      }).catch(error => {
        console.log(error);
      })
  }

  render() {
    return (
       !this.props.isAuthenticated ?
        <Redirect to="/" /> 
        :
        <header className="topnavbar-wrapper">
        <TimeOut/>
        {/* {this.state.redirect && <Redirect to="/" />}
        {!this.props.isAuthenticated && <Redirect to="/" />} */}
        {/* START Top Navbar */}
        <nav className="navbar topnavbar">
          {/* START navbar header */}
          <div className="navbar-header">
            <a className="navbar-brand" href="#/">
              <div className="brand-logo">
                <img className="img-fluid" src="img/logo.png" alt="App Logo" />
              </div>
              <div className="brand-logo-collapsed">
                <img
                  className="img-fluid"
                  src="img/logo-single.png"
                  alt="App Logo"
                />
              </div>
            </a>
          </div>
          {/* END navbar header */}

          {/* START Left navbar */}
          <ul className="navbar-nav mr-auto flex-row">
            <li className="nav-item">
              {/* Button used to collapse the left sidebar. Only visible on tablet and desktops */}
              <a
                href=""
                className="nav-link d-none d-md-block d-lg-block d-xl-block"
                onClick={this.toggleCollapsed}
              >
                <em className="fas fa-bars"></em>
              </a>
              {/* Button to show/hide the sidebar on mobile. Visible on mobile only. */}
              <a
                href=""
                className="nav-link sidebar-toggle d-md-none"
                onClick={this.toggleAside}
              >
                <em className="fas fa-bars"></em>
              </a>
            </li>
            {/* START User avatar toggle */}
            <li className="nav-item d-none d-md-block">
              <a className="nav-link" onClick={this.toggleUserblock}>
                <em className="icon-user"></em>
              </a>
            </li>
            { /* START Download menu */ }
            <UncontrolledDropdown nav inNavbar className="dropdown-list">
                <DropdownToggle nav className="dropdown-toggle-nocaret">
                  <em class="fa fa-download"></em>
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-right animated flipInX">
                    <DropdownItem>
                      <ListGroup>
                        {this.state.templates != null && this.state.templates.length > 0 && this.state.templates.map(item => (
                          <a href={item.template} style={{fontSize: "0.6em"}} download={item.name +" Template.xlsx"} className="btn btn-outline btn-rounded waves-effect btn-xs p-1">
                            {item.name +" Template"}
                          </a>
                        ))}
                        {(this.props.isAuthenticated == true && this.props.token[0].user_pisc_id > 1) && this.state.quarterly_template != null && this.state.quarterly_template.length > 0 && this.state.quarterly_template.map(item => (
                          <a href={item.template} style={{fontSize: "0.6em"}} download={item.name +" Template.xlsx"} className="btn btn-outline btn-rounded waves-effect btn-xs p-1">
                            {item.name +" Template"}
                          </a>
                        ))}
                      </ListGroup>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            { /* END Download menu */ }
          </ul>
        </nav>
      </header>
    );
  }
}

Header.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
  token: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  settings: state.settings,
  token: state.token.userData,
  user_token: state.token.user_token,
  isAuthenticated: state.token.isAuthenticated
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
