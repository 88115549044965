import { AUTH_LOGIN, AUTH_LOGOUT } from "../../actions/types";
import axios from "axios";
import { USERS_URL } from "../../../components/constant";


// export const loginAuth = (login_payload) => {
//     return dispatch => {
//         axios.post(USERS_URL + '/users/login', login_payload)
//         .then((response)=>{
//             dispatch({
//                 type: AUTH_LOGIN,
//                 payload: response.data
//             })
//             console.log(response.data)
//         })
//         .catch((error)=>{
//             console.log("There is no user with the given username and password");
//             //alert("Username does not exist");
//         })
//     };
// };

export const loginAuth = (payload) => {
    return dispatch => {
        
        dispatch({
            type: AUTH_LOGIN,
            payload: payload
        })

    };
};



export const logOut = () => {
    //console.log(user_id);
    return dispatch => {
        // axios.post(USERS_URL + '/users/user/logout', {id: user_id}, {
        //     headers: {
        //         "Content-Type": "application/json"
        //     }
        // })
        // .then((response)=>{
        //     console.log(response);
            dispatch({
                type: AUTH_LOGOUT
            })
            console.log("yes author")
        // })
        // .catch((error)=>{
        //     console.log("There is no user with the given username and password");
        //     //alert("Username does not exist");
        // })
    };
};
