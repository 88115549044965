import { FETCH_BRANCHES, NEW_BRANCH, UPDATE_BRANCH, DELETE_BRANCH } from "../../actions/types";

const initialState = {
  items: [],
  item: {},
  itemUpdated:{},
  itemDel:{},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_BRANCHES:
      return {
        ...state,
        items: action.payload
      };

    case NEW_BRANCH:
      return {
        ...state,
        item: action.payload
      };

    case UPDATE_BRANCH:
      return {
        ...state,
        itemUpdated: action.payload
      };

    case DELETE_BRANCH:
      return {
        ...state,
        itemDel: action.payload
      };

    default:
      return state;
  }
}
