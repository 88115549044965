import { FETCH_POSTS, FETCH_MEMBERS} from "../../actions/fars/typefars";

const initialState = {
  items: [],
  auditItems:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_POSTS:
      return {
        ...state,
        items: action.payload
      };

      case FETCH_MEMBERS:
        return {
          ...state,
          auditItems: action.payload
        };
    default:
      return state;
  }
}
