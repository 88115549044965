import { DQR, DATOR, DDER, DDAR, DCR, DCRR, DSCR, DWCTOR, DWCRA, DNPMR, DOPMR, DRoA, DRoE, DRoC, DFY } from "../../actions/fars/rd";

const initialState = {
    DQR : [],
    DASTOR : [],
    DDER : [],  
    DDAR : [],   
    DCR : [],
    DCCR : [],
    DSCR : [],
    DWCTOR : [],
    DWCRA : [],
    DNPMR : [],
    DOPMR : [],
    DRoA : [],
    DRoE : [],
    DRoC : [],
    DFYS : []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DQR:
      return {
            ...state,
            DQR: action.payload
      };

      case DATOR:
        return {
            ...state,
            DASTOR: action.payload
        };

    case DDER:
        return {
            ...state,
            DDER: action.payload
        };
    
    case DDAR:
        return {
            ...state,
            DDAR: action.payload
        };

    case DCR:
        return {
            ...state,
            DCR: action.payload
        };
        
    case DCRR:
        return {
            ...state,
            DCCR: action.payload
        };

    case DSCR:
        return {
            ...state,
            DSCR: action.payload
        };

    case DWCTOR:
        return {
            ...state,
            DWCTOR: action.payload
        };

    case DWCRA:
        return {
            ...state,
            DWCRA: action.payload
        };

    case DNPMR:
        return {
            ...state,
            DNPMR: action.payload
        };
    
    case DOPMR:
        return {
            ...state,
            DOPMR: action.payload
        };

    case DRoA:
        return {
            ...state,
            DRoA: action.payload
        };

    case DRoE:
        return {
            ...state,
            DRoE: action.payload
        };

    case DRoC:
        return {
            ...state,
            DRoC: action.payload
        };

    case DFY:
        return {
            ...state,
            DFYS: action.payload
        };

    default:
      return state;
  }
}


