import { FETCH_PERMISSIONS, NEW_PERMISSION, UPDATE_PERMISSION, DELETE_PERMISSION } from "../../actions/users/types";

const initialState = {
  items: [],
  item: {},
  itemUpdated:{},
  itemDel:{},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PERMISSIONS:
      return {
        ...state,
        items: action.payload
      };

    case NEW_PERMISSION:
      return {
        ...state,
        item: action.payload
      };

    case UPDATE_PERMISSION:
      return {
        ...state,
        itemUpdated: action.payload
      };

    case DELETE_PERMISSION: 
      return {
        ...state,
        itemDel: action.payload
      };

    default:
      return state;
  }
}
