export const FETCH_POSTS = "FETCH_POSTS";
export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const FETCH_MEMBERS1 = "FETCH_MEMBERS1";

export const WCTO = "WCTO";
export const WC = "WC";
export const NPM = "NPM";
export const OPM = "OPM";
export const TRev = "TRev";
export const TExp = "TExp";

export const QR = "QR";
export const ATOR = "ATOR";
export const DER = "DER";
export const DAR = "DAR";
export const CR = "CR";
export const CRR = "CRR";
export const SCR = "SCR";
export const RoA = "RoA";
export const RoE = "RoE";
export const RoC = "RoC"
export const WCTOR = "WCTOR";
export const WCRA = "WCRA";
export const NPMR = "NPMR";
export const OPMR = "OPMR";
export const FY = "FY";
