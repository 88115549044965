import React, { Suspense, lazy } from "react";
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import PageLoader from "./components/Common/PageLoader";
import Base from "./components/Layout/Base";
import BasePage from "./components/Layout/BasePage";
import ProtectedRoute from "./protected.routes";
const waitFor = Tag => props => <Tag {...props} />;

function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

//LOGIN
const Login = lazy(() => retry(() => import("./components/User/Login")));
const LoginAppointee = lazy(() => retry(() => import("./components/User/LoginAppointee")));
const Logout = lazy(() => retry(() => import("./components/User/Logout")));
const RecoverPassword = lazy(() => retry(() => import("./components/User/RecoverPassword")));
const OfficialAuthLink = lazy(() => retry(() => import("./components/User/OfficialAuthLink")));

//USER MANAGEMENT
const Roles = lazy(() => retry(() => import("./components/User/Roles")));
const EditRole = lazy(() => retry(() => import("./components/User/EditRole")));
const Permissions = lazy(() => retry(() => import("./components/User/Permissions")));
const Modules = lazy(() => retry(() => import("./components/User/Modules")));
const User = lazy(() => retry(() => import("./components/User/User")));
const UserProfile = lazy(() => retry(() => import("./components/User/UserProfile")));
const ChangePassword = lazy(() => retry(() => import("./components/User/ChangePassword")));
const CreateUser = lazy(() => retry(() => import("./components/User/CreateUser")));
const EditUser = lazy(() => retry(() => import("./components/User/EditUser")));
const AssignSector = lazy(() => retry(() => import("./components/User/AssignSector")));
const EditUserPermissions = lazy(() => retry(() => import("./components/User/EditUserPermissions")));
const GovernmentOfficials = lazy(() => retry(() => import("./components/User/GovernmentOfficials")));
const CreateGovernmentOfficials = lazy(() => retry(() => import("./components/User/CreateGovOfficials")));
const EditGovernmentOfficials = lazy(() => retry(() => import("./components/User/EditGovOfficials")));
const MemberProfile = lazy(() => retry(() => import("./components/User/MemberProfile")));
const AddEducationLevel = lazy(() => retry(() => import("./components/Appointee/addEducationLevel")));
const EditEducationLevel = lazy(() => retry(() => import("./components/Appointee/editEducationLevel")));
const AddPublication = lazy(() => retry(() =>import("./components/Appointee/addPublication")));
const AddProfessional = lazy(() => retry(() =>import("./components/Appointee/addProfessional")));

// Jeliel update board cv member
const EditEducationAppointee = lazy(() => retry(() => import("./components/Appointee/editEducationAppointee")));
const EditSkillAppointee = lazy(() => retry(() => import("./components/Appointee/editSkillsAppointee")));
const EditExperienceAppointee = lazy(() => retry(() => import("./components/Appointee/editExperienceAppointee")));
const EditPublication = lazy(() => retry(() => import("./components/Appointee/editPublication")));
const EditProfessional = lazy(() => retry(() => import("./components/Appointee/editProfessional")));





//NTR COLLECTION
const CreateBill = lazy(() => retry(() => import("./components/NonTaxRevenues/billForm")));
const IncompleteBill = lazy(() => retry(() => import("./components/NonTaxRevenues/incompleteBill")));
const BillStatus = lazy(() => retry(() => import("./components/NonTaxRevenues/billStatus")));
const Payment = lazy(() => retry(() => import("./components/NonTaxRevenues/payment")));
const Reconciliation = lazy(() => retry(() => import("./components/NonTaxRevenues/reconciliation")));
const Projection = lazy(() => retry(() => import("./components/NonTaxRevenues/projection")));
const ViewProjection = lazy(() => retry(() => import("./components/NonTaxRevenues/viewProjection")));
const EditProjection = lazy(() => retry(() => import("./components/NonTaxRevenues/editProjection")));
const Collection = lazy(() => retry(() => import("./components/NonTaxRevenues/collection")));

//BOARD MANAGEMENT
const Appointee = lazy(() => retry(() => import("./components/Appointee/appointee")));
const AppointeeEdit = lazy(() => retry(() => import("./components/Appointee/appointeeEdit")));
const EditProspectiveMember = lazy(() => retry(() => import("./components/Appointee/editProspectiveMember")));
const AppointeeDelete = lazy(() => retry(() => import("./components/Appointee/appointeeDelete")));
const AppointeeVerify = lazy(() => retry(() => import("./components/Appointee/appointeeVerify")));
const AppointmentDelete = lazy(() => retry(() => import("./components/Appointments/appointmentDelete")));
const Appointment = lazy(() => retry(() => import("./components/Appointments/appointment")));
const AppointmentEdit = lazy(() => retry(() => import("./components/Appointments/appointmentEdit")));
const Appointments = lazy(() => retry(() => import("./components/Appointments/Appointments")));
const AppointmentProgress = lazy(() => retry(() => import("./components/Appointments/AppointmentProgress")));
const AppointedMember = lazy(() => retry(() => import("./components/Appointments/AppointedMember")));
const InActiveBoardsReports = lazy(() => retry(() => import("./components/Appointments/InActiveBoardsReports")));
const InActiveBoardMembers = lazy(() => retry(() => import("./components/Appointments/InActiveBoardMembers")));
const ActiveBoardsReports = lazy(() => retry(() => import("./components/Appointments/ActiveBoardsReports")));
const boardMembers = lazy(() => retry(() => import("./components/Appointments/boardMembers")));
const PreviousBoards = lazy(() => retry(() => import("./components/Appointments/PreviousBoards")));
const PreviousBoardMembers = lazy(() => retry(() => import("./components/Appointments/previousBoardMembers")));
const PiscBoardReport = lazy(() => retry(() =>import("./components/Appointments/PiscBoardReport")));
const AddInstitution = lazy(() => retry(() =>import("./components/Appointee/AddInstitution")));
const Institutions = lazy(() => retry(() =>import("./components/Setup/Instituion/Institutions")));
const EditInstitution = lazy(() => retry(() =>import("./components/Setup/Instituion/EditInstitution")));

// Jeliel author for Board Members' CV
const Education = lazy(() => retry(() =>import("./components/Appointee/addEducationLevel")))
const WorkExprience = lazy(() => retry(() =>import("./components/Appointee/addWorkExperience")))
const Skills = lazy(() => retry(() =>import("./components/Appointee/addSkill")))
const EducationDelete = lazy(() => retry(() => import("./components/Appointee/educationDelete")));
const ProfessionalDelete = lazy(() => retry(() => import("./components/Appointee/professionalDelete")));
const SkillDelete = lazy(() => retry(() => import("./components/Appointee/skillDelete")));
const WorkExprienceDelete = lazy(() => retry(() => import("./components/Appointee/workExperienceDelete")));
const PublicationDelete = lazy(() => retry(() => import("./components/Appointee/publicationDelete")));







//PISC
const AddPisc = lazy(() => retry(() => import("./components/PISC/piscAdd")));
const MinorityInterests = lazy(() => retry(() => import("./components/PISC/minorities")));
const piscInvestments = lazy(() => retry(() => import("./components/PISC/piscInvestments")));
const privatizedEnterprises = lazy(() => retry(() => import("./components/PISC/privatizedEnterprises")));
const deletePisc = lazy(() => retry(() => import("./components/PISC/deletePisc")));
const piscOnDeletion = lazy(() => retry(() => import("./components/PISC/delete_pisc")));
const piscUpdate = lazy(() => retry(() => import("./components/PISC/piscUpdate")));
const CreatePisc = lazy(() => retry(() => import("./components/PISC/CreatePisc")));
const createBranchOffice = lazy(() => retry(() => import("./components/PISC/createBranchOffice")));
const UpdateVerifyPisc = lazy(() => retry(() => import("./components/PISC/UpdateVerifyPisc")));
const UpdatePisc = lazy(() => retry(() => import("./components/PISC/UpdatePisc")));
const PiscShare = lazy(() => retry(() => import("./components/PISC/Share")));
const PiscShareEdit = lazy(() => retry(() => import("./components/PISC/ShareEdit")));
const PiscDetailEdit = lazy(() => retry(() => import("./components/PISC/PiscDetailEdit")));
const EditPisc = lazy(() => retry(() => import("./components/PISC/EditPisc")));
const Decline = lazy(() => retry(() => import("./components/PISC/Decline")));
const History = lazy(() => retry(() => import("./components/PISC/History")));
const HistoryUpdate = lazy(() => retry(() => import("./components/PISC/HistoryUpdate")));
const Progress = lazy(() => retry(() => import("./components/PISC/Progress")));
const Reject = lazy(() => retry(() => import("./components/PISC/Reject")));
const PiscDetails = lazy(() => retry(() => import("./components/PISC/PiscDetails")));
const PiscUpdateDetails = lazy(() => retry(() => import("./components/PISC/PiscUpdateDetails")));
const Authorize = lazy(() => retry(() => import("./components/PISC/Authorize")));
const AuthorizeUpdate = lazy(() => retry(() => import("./components/PISC/AuthorizeUpdate")));
const RejectedPiscs = lazy(() => retry(() => import("./components/PISC/RejectedPiscs")));
const UpdateDeclinedPisc = lazy(() => retry(() => import("./components/PISC/UpdateDeclinedPisc")));
const ShowPisc = lazy(() => retry(() => import("./components/Piscs/ShowPisc.js")));
const RepProgress = lazy(() => retry(() => import("./components/Representatives/onProgress")));
const Representative = lazy(() => retry(() => import("./components/Representatives/Representative")));
const addRepresentative = lazy(() => retry(() => import("./components/Representatives/addRepresentative")));
const RepresentativeProfile = lazy(() => retry(() => import("./components/Representatives/RepresentativeProfile")));
const editRepresentative = lazy(() => retry(() => import("./components/Representatives/editRepresentative")));
const declinedRepresentative = lazy(() => retry(() => import("./components/Representatives/declinedRepresentative")));
const deleteRepresentative = lazy(() => retry(() => import("./components/Representatives/deleteRepresentative")));
const Branches = lazy(() => retry(() => import("./components/Piscs/Branches")));
const ShowPiscDetails = lazy(() => retry(() => import("./components/PISC/ShowPiscDetails")));
const piscEstablishment = lazy(() => retry(() => import("./components/PISC/piscEstablishment")));
const brancheOffices = lazy(() => retry(() => import("./components/PISC/piscBrancheOffices")));
const addresses = lazy(() => retry(() => import("./components/PISC/piscAddresses")));

// MANAGEMENT
const Organogram = lazy(() => retry(() => import("./components/Management/organogram")));
const Management = lazy(() => retry(() => import("./components/Management/managements")));
const PreviousManagement = lazy(() => retry(() => import("./components/Management/previousManagements")));
const ManagementMembers = lazy(() => retry(() => import("./components/Management/managementMembers")));
const PreviousManagementMembers = lazy(() => retry(() => import("./components/Management/previousManagementMembers")));
const PreviousMemberDetails = lazy(() => retry(() => import("./components/Management/previousMemberDetails")));
const AddMember = lazy(() => retry(() => import("./components/Management/addMember")));
const MemberDetails = lazy(() => retry(() => import("./components/Management/memberDetails")));
const EditMember = lazy(() => retry(() => import("./components/Management/editMember")));

// SUBSIDIARIES, JVs, EQUITY INVESTMENTS
const Subsidiaries = lazy(() => retry(() => import("./components/PiscShare/Subsidiary/Subsidiaries")));
const AddSubsidiary = lazy(() => retry(() => import("./components/PiscShare/Subsidiary/AddSubsidiary")));
const EditSubsidiary = lazy(() => retry(() => import("./components/PiscShare/Subsidiary/EditSubsidiary")));
const ViewSubsidiary = lazy(() => retry(() => import("./components/PiscShare/Subsidiary/ViewCompany")));

const SubsidiaryInvestments = lazy(() => retry(() => import("./components/PiscShare/SubsidiaryInvestment/SubsidiaryInvestments")));
const AddSubsidiaryInvestment = lazy(() => retry(() => import("./components/PiscShare/SubsidiaryInvestment/AddSubsidiaryInvestment")));
const EditSubsidiaryInvestment = lazy(() => retry(() => import("./components/PiscShare/SubsidiaryInvestment/EditSubsidiaryInvestment")));
const ProgressSubsidiaryInvestment = lazy(() => retry(() => import("./components/PiscShare/SubsidiaryInvestment/ProgressSubsidiaryInvestment")));

const SharePartners = lazy(() => retry(() => import("./components/PiscShare/SubsidiaryPartner/SubsidiaryPartners")));
const AddSharePartner = lazy(() => retry(() => import("./components/PiscShare/SubsidiaryPartner/AddSubsidiaryPartner")));
const ProgressSharePartners = lazy(() => retry(() => import("./components/PiscShare/SubsidiaryPartner/ProgressSubsidiaryPartners")));
const ViewProgressSharePartner = lazy(() => retry(() => import("./components/PiscShare/SubsidiaryPartner/ViewProgressSubsidiaryPartner")));
const DeclinedSharePartners = lazy(() => retry(() => import("./components/PiscShare/SubsidiaryPartner/DeclinedSubsidiaryPartners")));
const EditDeclinedSharePartner = lazy(() => retry(() => import("./components/PiscShare/SubsidiaryPartner/EditDeclinedSubsidiaryPartner")));

const EquityIssuer = lazy(() => retry(() => import("./components/PiscShare/EquityIssuer/EquityIssuers")));
const AddEquityIssuer = lazy(() => retry(() => import("./components/PiscShare/EquityIssuer/AddEquityIssuer")));
const EditEquityIssuer = lazy(() => retry(() => import("./components/PiscShare/EquityIssuer/EditEquityIssuer")));

const JvPartners = lazy(() => retry(() => import("./components/PiscShare/JvPartner/JvPartners")));
const AddJvPartner = lazy(() => retry(() => import("./components/PiscShare/JvPartner/AddJvPartner")));
const EditJvPartner = lazy(() => retry(() => import("./components/PiscShare/JvPartner/EditJvPartner")));

const JvInvestments = lazy(() => retry(() => import("./components/PiscShare/JvInvestment/JvInvestments")));
const AddJvInvestment = lazy(() => retry(() => import("./components/PiscShare/JvInvestment/AddJvInvestment")));
const EditJvInvestment = lazy(() => retry(() => import("./components/PiscShare/JvInvestment/EditJvInvestment")));
const ProgressJvInvestment = lazy(() => retry(() => import("./components/PiscShare/JvInvestment/ProgressJvInvestments")));

const JvInvestmentPartners = lazy(() => retry(() => import("./components/PiscShare/JvInvestmentPartner/JvInvestmentPartners")));
const AddJvInvestmentPartner = lazy(() => retry(() => import("./components/PiscShare/JvInvestmentPartner/AddJvInvestmentPartner")));
const EditJvInvestmentPartner = lazy(() => retry(() => import("./components/PiscShare/JvInvestmentPartner/EditJvInvestmentPartner")));

const JointVentures = lazy(() => retry(() => import("./components/PiscShare/JointVenture/JointVentures")));
const AddJointVenture = lazy(() => retry(() => import("./components/PiscShare/JointVenture/AddJointVenture")));
const EditJointVenture = lazy(() => retry(() => import("./components/PiscShare/JointVenture/EditJointVenture")));

const EquityInvestments = lazy(() => retry(() => import("./components/PiscShare/EquityInvestment/EquityInvestments")));
const AddEquityInvestment = lazy(() => retry(() => import("./components/PiscShare/EquityInvestment/AddEquityInvestment")));
const EditEquityInvestment = lazy(() => retry(() => import("./components/PiscShare/EquityInvestment/EditEquityInvestment")));
const ProgressEquityInvestment = lazy(() => retry(() => import("./components/PiscShare/EquityInvestment/ProgressEquityInvestments")));
const ViewEquityInvestment = lazy(() => retry(() => import("./components/PiscShare/EquityInvestment/ViewEquityInvestments")));

const PiscInvestments = lazy(() => retry(() => import("./components/PISC/piscInvestments")));
const PiscInvestmentsProgress = lazy(() => retry(() => import("./components/PISC/PiscInvestmentsProgress")));

//SETUP
const Sectors = lazy(() => retry(() => import("./components/Setup/Sectors")));
const Regulators = lazy(() => retry(() => import("./components/Setup/Regulators")));
const Clusters = lazy(() => retry(() => import("./components/Setup/Clusters")));
const Cluster = lazy(() => retry(() => import("./components/Setup/Cluster")));
const Subclusters = lazy(() => retry(() => import("./components/Setup/Subclusters")));
const Subcluster = lazy(() => retry(() => import("./components/Setup/Subcluster")));
const Ministries = lazy(() => retry(() => import("./components/Setup/Ministries")));
const Ministry = lazy(() => retry(() => import("./components/Setup/Ministry")));
const Regions = lazy(() => retry(() => import("./components/Setup/Regions")));
const Region = lazy(() => retry(() => import("./components/Setup/Region")));
const Districts = lazy(() => retry(() => import("./components/Setup/Districts")));
const District = lazy(() => retry(() => import("./components/Setup/District")));
const Classfications = lazy(() => retry(() => import("./components/Setup/Classfications")));
const Classfication = lazy(() => retry(() => import("./components/Setup/Classfication")));
const CapitalNatures = lazy(() => retry(() => import("./components/Setup/CapitalNatures")));
const CapitalNature = lazy(() => retry(() => import("./components/Setup/CapitalNature")));
const OperationNatures = lazy(() => retry(() => import("./components/Setup/OperationNatures")));
const OperationNature = lazy(() => retry(() => import("./components/Setup/OperationNature")));
const Establishments = lazy(() => retry(() => import("./components/Setup/Establishments")));
const Establishment = lazy(() => retry(() => import("./components/Setup/Establishment")));
const ReportingPeriods = lazy(() => retry(() => import("./components/Setup/ReportingPeriods")));
const ReportingPeriod = lazy(() => retry(() => import("./components/Setup/ReportingPeriod")));
const ReportingTemplates = lazy(() => retry(() => import("./components/Setup/ReportingTemplates")));
const ReportingTemplate = lazy(() => retry(() => import("./components/Setup/ReportingTemplate")));
const ModeOfChanges = lazy(() => retry(() => import("./components/Setup/ModeOfChanges")));
const ModeOfChange = lazy(() => retry(() => import("./components/Setup/ModeOfChange")));
const documentTypes = lazy(() => retry(() => import("./components/Setup/documentTypes")));
const documentType = lazy(() => retry(() => import("./components/Setup/documentType")));
const Positions = lazy(() => retry(() => import("./components/Setup/Positions")));
const Position = lazy(() => retry(() => import("./components/Setup/Position")));
const BoardTypes = lazy(() => retry(() => import("./components/Setup/BoardTypes")));
const BoardType = lazy(() => retry(() => import("./components/Setup/BoardType")));
const Quarters = lazy(() => retry(() => import("./components/Setup/Quarters")));
const QuarterMonths = lazy(() => retry(() => import("./components/Setup/QuarterMonths")));
const GovernmentPositions = lazy(() => retry(() => import("./components/Setup/GovernmentPositions")));

//FARS DATA COLLECTION
const FinancialAnalysis = lazy(() => retry(() => import("./components/FarsAnalysis/FinancialAnalysis")));
// const statements = lazy(() => retry(() => import("./components/fars/Statements")));
const FarsQuarteryUploadings  = lazy(() => retry(() => import("./components/fars/FarsQuarteryUploadings")));
const FarsAuditedUploadings = lazy(() => retry(() => import("./components/fars/FarsAuditedUploadings")));
const FinancialHistory = lazy(() => retry(() => import("./components/fars/financialHistory")));
const ApprovedAuditedReports = lazy(() => retry(() => import("./components/fars/ApprovedAuditedReports")));
const ApprovedQuarterlyReports = lazy(() => retry(() => import("./components/fars/ApprovedQuarterlyReports")));
const ListQuarterlyReports = lazy(() => retry(() => import("./components/fars/ListQuarterlyReports")));
const AuditedReport = lazy(() => retry(() => import("./components/fars/AuditedReport")));
const QuarterlyReport = lazy(() => retry(() => import("./components/fars/QuarterlyReport")));
const AnalysisComments = lazy(() => retry(() => import("./components/FarsAnalysis/AnalysisComments")));

//DOCUMENTS
const editDocument = lazy(() => retry(() => import("./components/Document/editDocument")));
const verifyDocument = lazy(() => retry(() => import("./components/Document/verifyDocument")));
const rejectDocument = lazy(() => retry(() => import("./components/Document/rejectDocument")));
const deleteDocument = lazy(() => retry(() => import("./components/Document/deleteDocument")));
const deleteDocumentProgress = lazy(() => retry(() => import("./components/Document/deleteDocumentProgress")));
const documentProgress = lazy(() => retry(() => import("./components/Document/documentProgress")));
const upload = lazy(() => retry(() => import("./components/Document/Upload")));
const document = lazy(() => retry(() => import("./components/Document/Document")));
const viewDocument = lazy(() => retry(() => import("./components/Document/viewDocument")));
const userManual = lazy(() => retry(() => import("./components/Document/userManual")));

//REPORTS
const FarsManagement = lazy(() => retry(() => import("./components/Reports/FarsManagement")));
const BoardManagement = lazy(() => retry(() => import("./components/Reports/BoardManagement")));
const PortifolioManagement = lazy(() => retry(() => import("./components/Reports/PortifolioManagement")));
const PscManagement = lazy(() => retry(() => import("./components/Reports/PscManagement")));

//DASHBOARD
const PiscDashboard = lazy(() => retry(() => import("./components/Dashboard/PiscDashboard")));
const MainDashboard = lazy(() => retry(() => import("./components/Dashboard/MainDashboard")));

// SWITCH BTN LAYOUTS
const listofPages = ["/recover", "/", "/prospective-login", "/logout"];
const Routes = ({ location }) => {
const currentKey = location.pathname.split("/")[1] || "/";
const timeout = { enter: 500, exit: 500 };
const animationName = "rag-fadeInRight";
if (listofPages.indexOf(location.pathname) > -1) {

  //WRAPPER
  return (
    <BasePage>
      <Suspense fallback={<PageLoader />}>
        <Switch location={location}>
          <BasePage>
            <Suspense fallback={<PageLoader />}>
              <Switch location={location}>
                <Route exact path="/" component={waitFor(Login)} />
                <Route exact path="/prospective-login" component={waitFor(LoginAppointee)} />
                  <Route path="/logout" component={waitFor(Logout)} />
                <ProtectedRoute path="/recover" component={waitFor(RecoverPassword)} />
              </Switch>
            </Suspense>
          </BasePage>
        </Switch>
      </Suspense>
    </BasePage>
  );
} else {

  //WRAPPER << Use 'BaseHorizontal' to change layout >>
    return (
      <Base>
        <TransitionGroup>
          <CSSTransition
            key={currentKey}
            timeout={timeout}
            classNames={animationName}
            exit={false}
          >
            <div>
              <Suspense fallback={<PageLoader />}>
                <Switch location={location}>
                   <Route exact path="/waiting/:id" component={waitFor(OfficialAuthLink)} />
                  
                  {/* USER MANAGEMENT */}
                  <ProtectedRoute path="/users/roles" component={waitFor(Roles)} />
                  <ProtectedRoute path="/users/editRole/:id" component={waitFor(EditRole)}/>
                  <ProtectedRoute path="/users/permissions" component={waitFor(Permissions)}/>
                  <ProtectedRoute path="/users/modules" component={waitFor(Modules)} />
                  <ProtectedRoute path="/users/user" component={waitFor(User)} />
                  <ProtectedRoute path="/users/create_user" component={waitFor(CreateUser)}/>
                  <ProtectedRoute path="/users/edit_user/:id" component={waitFor(EditUser)}/>
                  <ProtectedRoute path="/user_profile" component={waitFor(UserProfile)}/>
                  <ProtectedRoute path="/change_password/:id/:origin" component={waitFor(ChangePassword)}/>
                  <ProtectedRoute path="/users/assign_sector" component={waitFor(AssignSector)}/>
                  <ProtectedRoute path="/users/edit_assigned_sector/:id" component={waitFor(AssignSector)}/>
                  <ProtectedRoute path="/users/edit_user_permissions" component={waitFor(EditUserPermissions)}/>
                  <ProtectedRoute path="/pisc_establishment" component={waitFor(piscEstablishment)} />
                  <ProtectedRoute path="/pisc_branches" component={waitFor(brancheOffices)} />
                  <ProtectedRoute path="/pisc_addresses" component={waitFor(addresses)} />
                  <ProtectedRoute path="/users/government-officials" component={waitFor(GovernmentOfficials)} />
                  <ProtectedRoute path="/users/create-govn-official"  component={waitFor(CreateGovernmentOfficials)} />
                  <ProtectedRoute path="/users/edit-govn-official/:id" component={waitFor(EditGovernmentOfficials)} />
                  <ProtectedRoute path="/member_profile/:appointee_id/:origin" component={waitFor(MemberProfile)} />
                  <ProtectedRoute path="/add_education_level/:appointee_id" component={waitFor(AddEducationLevel)} />
                  <ProtectedRoute path="/edit_education_level/:appointee_id" component={waitFor(EditEducationLevel)} />
                  <ProtectedRoute path="/add_publication/:appointee_id" component={waitFor(AddPublication)} />
                  <ProtectedRoute path="/add_professional/:appointee_id" component={waitFor(AddProfessional)} />


                  {/* Jeliel upate board cv here */}
                  <ProtectedRoute path="/edit_education_appointee/:appointee_id/:education_id" component={waitFor(EditEducationAppointee)} />
                  <ProtectedRoute path="/edit_experience_appointee/:appointee_id/:experience_id" component={waitFor(EditExperienceAppointee)} />
                  <ProtectedRoute path="/edit_skills_appointee/:appointee_id/:skill_id" component={waitFor(EditSkillAppointee)} />
                  <ProtectedRoute path="/edit_publication_appointee/:appointee_id/:publication_id" component={waitFor(EditPublication)} />
                  <ProtectedRoute path="/edit_professional_appointee/:appointee_id/:professional_id" component={waitFor(EditProfessional)} />



                  {/** PISC **/}
                  <ProtectedRoute path="/piscs" component={waitFor(AddPisc)} />
                  <ProtectedRoute path="/minority_interests" component={waitFor(MinorityInterests)} />
                  <ProtectedRoute path="/pisc_investments" component={waitFor(piscInvestments)} />
                  <ProtectedRoute path="/privatized_enterprises" component={waitFor(privatizedEnterprises)} />
                  <ProtectedRoute path="/deletePisc/:id/:action" component={waitFor(deletePisc)} />
                  <ProtectedRoute path="/piscOnDeletion" component={waitFor(piscOnDeletion)} />
                  <ProtectedRoute path="/piscUpdating/:id" component={waitFor(piscUpdate)} />
                  <ProtectedRoute path="/create_pisc" component={waitFor(CreatePisc)} />
                  <ProtectedRoute path="/create_branchOffice" component={waitFor(createBranchOffice)} />
                  <ProtectedRoute path="/update_pisc/:id" component={waitFor(UpdatePisc)} />
                  <ProtectedRoute path="/update_verify_pisc/:id" component={waitFor(UpdateVerifyPisc)} />
                  <ProtectedRoute path="/pisc_share" component={waitFor(PiscShare)} />
                  <ProtectedRoute path="/pisc_share_edit/:id" component={waitFor(PiscShareEdit)} />
                  <ProtectedRoute path="/pisc_detail_edit/:id" component={waitFor(PiscDetailEdit)} />
                  <ProtectedRoute path="/edit_pisc/:id" component={waitFor(EditPisc)} />
                  <ProtectedRoute path="/history_pisc" component={waitFor(History)} />
                  <ProtectedRoute path="/history_update_pisc" component={waitFor(HistoryUpdate)} />
                  <ProtectedRoute path="/progress_pisc" component={waitFor(Progress)} />
                  <ProtectedRoute path="/decline_piscs" component={waitFor(Decline)} />
                  <ProtectedRoute path="/showPisc/:id" component={waitFor(ShowPisc)} />
                  <ProtectedRoute path="/pisc_details/:id" component={waitFor(PiscDetails)} />
                  <ProtectedRoute path="/pisc_update_details/:id" component={waitFor(PiscUpdateDetails)} />
                  <ProtectedRoute path="/authorize_pisc/:id" component={waitFor(Authorize)} />
                  <ProtectedRoute path="/authorize_update_pisc/:id" component={waitFor(AuthorizeUpdate)} />
                  <ProtectedRoute path="/reject_pisc/:id" component={waitFor(Reject)} />
                  <ProtectedRoute path="/previousBoards" component={waitFor(PreviousBoards)} />
                  <ProtectedRoute path="/previousBoardMembers/:pisc_id" component={waitFor(PreviousBoardMembers)}/>
                  <ProtectedRoute path="/board_members/:pisc_id" component={waitFor(boardMembers)} />
                  <ProtectedRoute path="/rejected_piscs" component={waitFor(RejectedPiscs)} />
                  <ProtectedRoute path="/update_declined_pisc/:id" component={waitFor(UpdateDeclinedPisc)} />
                  <ProtectedRoute path="/in_active_boards_reports" component={waitFor(InActiveBoardsReports)} />
                  <ProtectedRoute path="/list_boards/:id" component={waitFor(InActiveBoardMembers)} />
                  <ProtectedRoute path="/active_boards_reports" component={waitFor(ActiveBoardsReports)} />
                  <ProtectedRoute path="/addInstitution/:id" component={waitFor(AddInstitution)} />
                  <ProtectedRoute path="/addInstitution/:id" component={waitFor(AddInstitution)} />
                  <ProtectedRoute path="/rep_progress" component={waitFor(RepProgress)} />
                  <ProtectedRoute path="/representatives" component={waitFor(Representative)} />
                  <ProtectedRoute path="/addRepresentative/:id" component={waitFor(addRepresentative)} />
                  <ProtectedRoute path="/representative_profile/:id" component={waitFor(RepresentativeProfile)} />
                  <ProtectedRoute path="/edit_representative/:id/:appointee_id" component={waitFor(editRepresentative)} />
                  <ProtectedRoute path="/declined_representative" component={waitFor(declinedRepresentative)} />
                  <ProtectedRoute path="/delete_representative/:id/:origin" component={waitFor(deleteRepresentative)} />
                  <ProtectedRoute path="/branches" component={waitFor(Branches)} />
                  <ProtectedRoute path="/show_pisc_details/:id" component={waitFor(ShowPiscDetails)} />
                  <ProtectedRoute path="/institutions" component={waitFor(Institutions)} />
                  <ProtectedRoute path="/institution/edit/:id" component={waitFor(EditInstitution)} />

                  {/* MANAGEMENT */}
                  <ProtectedRoute path="/organogram/:pisc_id" component={waitFor(Organogram)} />
                  <ProtectedRoute path="/management" component={waitFor(Management)} />
                  <ProtectedRoute path="/previousManagement" component={waitFor(PreviousManagement)} />
                  <ProtectedRoute path="/managementMembers/:pisc_id" component={waitFor(ManagementMembers)} />
                  <ProtectedRoute path="/previousManagementMembers/:pisc_id/:num_members" component={waitFor(PreviousManagementMembers)} />
                  <ProtectedRoute path="/addMember/:pisc_id" component={waitFor(AddMember)} />
                  <ProtectedRoute path="/memberDetails/:id" component={waitFor(MemberDetails)} />
                  <ProtectedRoute path="/previousMemberDetails/:id" component={waitFor(PreviousMemberDetails)} />
                  <ProtectedRoute path="/editMemberDetails/:id" component={waitFor(EditMember)} />

                  {/* SUBSIDIARIES, JVs, EQUITY INVESTMENTS */}
                  <ProtectedRoute path="/companies" component={waitFor(Subsidiaries)} />
                  <ProtectedRoute path="/company/add" component={waitFor(AddSubsidiary)} />
                  <ProtectedRoute path="/company/edit/:id" component={waitFor(EditSubsidiary)} />
                  <ProtectedRoute path="/view-company/:id/:type/:type_id" component={waitFor(ViewSubsidiary)} />

                  <ProtectedRoute path="/subsidiary-investments/:type/:type_id" component={waitFor(SubsidiaryInvestments)} />
                  <ProtectedRoute path="/add-govn-investment/:id/:type/:type_id" component={waitFor(AddSubsidiaryInvestment)} />
                  <ProtectedRoute path="/edit-govn-investment/:id/:type/:type_id" component={waitFor(EditSubsidiaryInvestment)} />
                  <ProtectedRoute path="/view_subsidiary_progress/:id/:company_id" component={waitFor(ProgressSubsidiaryInvestment)} />

                  <ProtectedRoute path="/share-partners/:id" component={waitFor(SharePartners)} />
                  <ProtectedRoute path="/add-share-partner/:id" component={waitFor(AddSharePartner)} />
                  <ProtectedRoute path="/progress-share-partners/" component={waitFor(ProgressSharePartners)} />
                  <ProtectedRoute path="/view-progress-share-partner/:id" component={waitFor(ViewProgressSharePartner)} />
                  <ProtectedRoute path="/declined-share-partners" component={waitFor(DeclinedSharePartners)} />
                  <ProtectedRoute path="/edit-declined-share-partner/:id" component={waitFor(EditDeclinedSharePartner)} />

                  <ProtectedRoute path="/equity-issuers" component={waitFor(EquityIssuer)} />
                  <ProtectedRoute path="/equity-issuer/add" component={waitFor(AddEquityIssuer)} />
                  <ProtectedRoute path="/equity-issuer/edit/:id" component={waitFor(EditEquityIssuer)} />
                  
                  <ProtectedRoute path="/joint-ventures" component={waitFor(JointVentures)} />
                  <ProtectedRoute path="/joint-venture/add" component={waitFor(AddJointVenture)} />
                  <ProtectedRoute path="/joint-venture/edit/:id" component={waitFor(EditJointVenture)} />

                  <ProtectedRoute path="/jv-partners" component={waitFor(JvPartners)} />
                  <ProtectedRoute path="/jv-partner/add" component={waitFor(AddJvPartner)} />
                  <ProtectedRoute path="/jv-partner/edit/:id" component={waitFor(EditJvPartner)} />

                  <ProtectedRoute path="/jv-investments/:type/:type_id" component={waitFor(JvInvestments)} />
                  <ProtectedRoute path="/jv-investment/add/:id/:type/:type_id" component={waitFor(AddJvInvestment)} />
                  <ProtectedRoute path="/jv-investment/progress/:id" component={waitFor(ProgressJvInvestment)} />
                  <ProtectedRoute path="/jv-investment/edit/:id/:type/:type_id" component={waitFor(EditJvInvestment)} />

                  <ProtectedRoute path="/jv-investment-partners" component={waitFor(JvInvestmentPartners)} />
                  <ProtectedRoute path="/jv-investment-partner/add" component={waitFor(AddJvInvestmentPartner)} />
                  <ProtectedRoute path="/jv-investment-partner/edit/:id" component={waitFor(EditJvInvestmentPartner)} />

                  <ProtectedRoute path="/equity-investments/:type/:type_id" component={waitFor(EquityInvestments)} />
                  <ProtectedRoute path="/equity-investment/add/:type/:type_id" component={waitFor(AddEquityInvestment)} />
                  <ProtectedRoute path="/equity-investment/edit/:id/:type/:type_id" component={waitFor(EditEquityInvestment)} />
                  <ProtectedRoute path="/equity-investment/progress/:id" component={waitFor(ProgressEquityInvestment)} />
                  <ProtectedRoute path="/equity-investment/view/:id/:type/:type_id" component={waitFor(ViewEquityInvestment)} />

                  <ProtectedRoute path="/pisc-investments" component={waitFor(PiscInvestments)} />
                  <ProtectedRoute path="/pisc-investments-progress" component={waitFor(PiscInvestmentsProgress)} />

                  {/** SETTINGS **/}
                  <ProtectedRoute path="/sector" component={waitFor(Sectors)} />
                  <ProtectedRoute path="/regulators" component={waitFor(Regulators)} />
                  <ProtectedRoute path="/cluster" component={waitFor(Clusters)} />
                  <ProtectedRoute path="/link_cluster/:id" component={waitFor(Cluster)} />
                  <ProtectedRoute path="/subcluster" component={waitFor(Subclusters)} />
                  <ProtectedRoute path="/link_subcluster/:id" component={waitFor(Subcluster)} />
                  <ProtectedRoute path="/ministry" component={waitFor(Ministries)} />
                  <ProtectedRoute path="/link_ministry/:id" component={waitFor(Ministry)} />
                  <ProtectedRoute path="/regions" component={waitFor(Regions)} />
                  <ProtectedRoute path="/link_region/:id" component={waitFor(Region)} />
                  <ProtectedRoute path="/districts" component={waitFor(Districts)} />
                  <ProtectedRoute path="/link_district/:id" component={waitFor(District)} />
                  <ProtectedRoute path="/classfication" component={waitFor(Classfications)} />
                  <ProtectedRoute path="/link_classfication/:id" component={waitFor(Classfication)} />
                  <ProtectedRoute path="/capitals" component={waitFor(CapitalNatures)} />
                  <ProtectedRoute path="/link_capital/:id" component={waitFor(CapitalNature)} />
                  <ProtectedRoute path="/operations" component={waitFor(OperationNatures)} />
                  <ProtectedRoute path="/link_operation/:id" component={waitFor(OperationNature)} />
                  <ProtectedRoute path="/establishments" component={waitFor(Establishments)} />
                  <ProtectedRoute path="/link_establishment/:id" component={waitFor(Establishment)} />
                  <ProtectedRoute path="/periods" component={waitFor(ReportingPeriods)} />
                  <ProtectedRoute path="/link_period/:id" component={waitFor(ReportingPeriod)} />
                  <ProtectedRoute path="/templates" component={waitFor(ReportingTemplates)} />
                  <ProtectedRoute path="/link_template/:id" component={waitFor(ReportingTemplate)} />
                  <ProtectedRoute path="/modes" component={waitFor(ModeOfChanges)} />
                  <ProtectedRoute path="/link_mode/:id" component={waitFor(ModeOfChange)} />
                  <ProtectedRoute path="/document_type" component={waitFor(documentTypes)} />
                  <ProtectedRoute path="/link_documentType/:id" component={waitFor(documentType)} />
                  <ProtectedRoute path="/position" component={waitFor(Positions)} />
                  <ProtectedRoute path="/link_position/:id" component={waitFor(Position)} />
                  <ProtectedRoute path="/board_type" component={waitFor(BoardTypes)} />
                  <ProtectedRoute path="/link_board_type/:id" component={waitFor(BoardType)} />
                  <ProtectedRoute path="/quarters" component={waitFor(Quarters)} />
                  <ProtectedRoute path="/months" component={waitFor(QuarterMonths)} />

                  {/* NTR COLLECTION */}
                  <ProtectedRoute path="/ntr/create_bill" component={waitFor(CreateBill)} />
                  <ProtectedRoute path="/ntr/incomplete_bill" component={waitFor(IncompleteBill)} />
                  <ProtectedRoute path="/ntr/bill_status" component={waitFor(BillStatus)} />
                  <ProtectedRoute path="/ntr/payment" component={waitFor(Payment)} />
                  <ProtectedRoute path="/ntr/reconciliation" component={waitFor(Reconciliation)} />
                  <ProtectedRoute path="/ntr/budget" component={waitFor(Projection)} />
                  <ProtectedRoute path="/ntr/view_budget/:id" component={waitFor(ViewProjection)} />
                  <ProtectedRoute path="/ntr/edit_budget/:id" component={waitFor(EditProjection)} />
                  <ProtectedRoute path="/ntr/collections" component={waitFor(Collection)} />

                  {/* BOARD MANAGEMENT */}
                  <ProtectedRoute path="/appointee" component={waitFor(Appointee)} />
                  <ProtectedRoute path="/edit_appointee/:id" component={waitFor(AppointeeEdit)} />
                  <ProtectedRoute path="/pisc_editAppointee/:appointee_id" component={waitFor(EditProspectiveMember)} />
                  <ProtectedRoute path="/delete_appointee/:id" component={waitFor(AppointeeDelete)} />
                  <ProtectedRoute path="/verify_appointee/:id" component={waitFor(AppointeeVerify)} />
                  <ProtectedRoute path="/delete_appointment/:id/:appointee_id" component={waitFor(AppointmentDelete)} />
                  <ProtectedRoute path="/government_positions" component={waitFor(GovernmentPositions)} />
                  <ProtectedRoute path="/appointee/:appointee_id" component={waitFor(Appointee)} />
                  <ProtectedRoute path="/appointment/:id" component={waitFor(Appointment)} />
                  <ProtectedRoute path="/edit_appointment/:appointee_id/:id" component={waitFor(AppointmentEdit)} />
                  <ProtectedRoute path="/appointment/:id"  component={waitFor(Appointment)} />
                  <ProtectedRoute path="/pisc-board-report" component={waitFor(PiscBoardReport)}/>
                  <ProtectedRoute path="/appointments" component={waitFor(Appointments)}/>
                  <ProtectedRoute path="/appointmentProgress" component={waitFor(AppointmentProgress)}/>
                  <ProtectedRoute path="/appointedMember/:id" component={waitFor(AppointedMember)} />

                  {/* Jeliel author here for Board members CV */}
                  <ProtectedRoute path="/add_education/:appointee_id" component={waitFor(Education)} />
                  <ProtectedRoute path="/add_work_exprience/:appointee_id" component={waitFor(WorkExprience)} />
                  <ProtectedRoute path="/add_skill/:appointee_id" component={waitFor(Skills)} />

                  {/* Professional delete */}
                  <ProtectedRoute path="/delete_professional/:professional_id/:appointee_id" component={waitFor(ProfessionalDelete)} />
                  <ProtectedRoute path="/delete_publication/:publication_id/:appointee_id" component={waitFor(PublicationDelete)} />
                  <ProtectedRoute path="/delete_education/:education_id/:appointee_id" component={waitFor(EducationDelete)} />
                  <ProtectedRoute path="/delete_work_experience/:appointee_id/:experience_id" component={waitFor(WorkExprienceDelete)} />
                  <ProtectedRoute path="/delete_skill/:appointee_id/:skill_id" component={waitFor(SkillDelete)} />



                  {/** FARS DATA COLLECTION */}
                  <ProtectedRoute path="/fars/financial-analysis" component={waitFor(FinancialAnalysis)}/>
                  {/* <ProtectedRoute path="/fars/statements/:id/:pisc_id" component={waitFor(statements)}/> */}
                  <ProtectedRoute path="/fars/quarterly_statements" component={waitFor(FarsQuarteryUploadings)}/>
                  <ProtectedRoute path="/fars/audited_statements" component={waitFor(FarsAuditedUploadings)}/>
                  <ProtectedRoute path="/fars/fars_reports" component={waitFor(FarsManagement)}/>
                  <ProtectedRoute path="/fars/financial_history" component={waitFor(FinancialHistory)}/>
                  <ProtectedRoute path="/fars/approvedAuditedReports/:pisc_id" component={waitFor(ApprovedAuditedReports)}/>
                  <ProtectedRoute path="/fars/approvedQuarterlyReports/:pisc_id" component={waitFor(ApprovedQuarterlyReports)}/>
                  <ProtectedRoute path="/fars/quarterlyReportsList/:pisc_id" component={waitFor(ListQuarterlyReports)}/>
                  <ProtectedRoute path="/fars/audited_report/:id/:pisc_id/:flag" component={waitFor(AuditedReport)}/>
                  <ProtectedRoute path="/fars/quarterly_report/:report_id/:pisc_id" component={waitFor(QuarterlyReport)}/>
                  <ProtectedRoute path="/fars/analysis-comments/:pisc_id/:metadata_id" component={waitFor(AnalysisComments)}/>
                  
                  {/**DOCUMENTS */}
                  <ProtectedRoute path="/edit_document/:id" component={waitFor(editDocument)} />
                  <ProtectedRoute path="/verify_document/:id" component={waitFor(verifyDocument)} />
                  <ProtectedRoute path="/reject_document/:id" component={waitFor(rejectDocument)} />
                  <ProtectedRoute path="/delete_document/:id" component={waitFor(deleteDocument)} />
                  <ProtectedRoute path="/delete_documentProgress/:id" component={waitFor(deleteDocumentProgress)} />
                  <ProtectedRoute path="/documents" component={waitFor(document)} />
                  <ProtectedRoute path="/piscDocuments/:pisc_id" component={waitFor(viewDocument)} />
                  <ProtectedRoute path="/documentProgress" component={waitFor(documentProgress)} />
                  <ProtectedRoute path="/upload" component={waitFor(upload)} />
                  <ProtectedRoute path="/userManual" component={waitFor(userManual)}/>

                  {/* DASHBOARDS */}
                  <ProtectedRoute path="/mainDashboard" component={waitFor(MainDashboard)} />
                  <ProtectedRoute path="/home" component={waitFor(PiscDashboard)} />

                  {/* REPORTS */}
                  <ProtectedRoute path="/financialManagement" component={waitFor(FarsManagement)} />
                  <ProtectedRoute path="/boardManagement" component={waitFor(BoardManagement)} />
                  <ProtectedRoute path="/portifolioManagement" component={waitFor(PortifolioManagement)} />
                  <ProtectedRoute path="/pscManagement" component={waitFor(PscManagement)} />

                  <Redirect to="/" />
                </Switch>
              </Suspense>
            </div>
          </CSSTransition>
        </TransitionGroup>
      </Base>
    );
}
};

export default withRouter(Routes);
