import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import { connect } from "react-redux";
import {
  logOut
} from "../../store/actions/users/loginAction";
import propTypes from "prop-types";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { USERS_URL } from "../constant";
 
class TimeOut extends Component {

  constructor(props) { 
    super(props)

    this.state = {
        redirect:false
    }

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
  }

  

  _onAction(e) {
    
    // window.onbeforeunload = function() {
    //   this.setState({
    //       redirect:true
    //   })
    // }
    //console.log('user did something', e)
  }
 
  _onActive(e) {
    // console.log('user is active', e)
    // console.log('time remaining', this.idleTimer.getRemainingTime())
  }
 
  _onIdle(e) {

    this.setState({
        redirect:true
    })
    // console.log('user is idle', e)
    // console.log('last active', this.idleTimer.getLastActiveTime())
  }

  checkLoginStatus = () => {

    // if(this.props.token == null && this.props.token.length == 0) {
    //   this.setState({
    //     redirect:true
    //   })
    // }else{
      axios.get(USERS_URL + "/users/user?user_id=" + this.props.token[0].user_id + "&user_token=" + this.props.user_token).then(res => {
        if(res.data === false){
          this.setState({
            redirect:true
          })
        }
        console.log(res.data);
      });  
    // }

    
  }
 
  render() {
    return (
      <div>

        <div>
            {this.state.redirect &&  <Redirect to='/logout'/>}
        </div>

        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 15} />
        {/* your app here */}
      </div>
    )
  }
 
  
}

TimeOut.propTypes = {
    //FUCTIONS REQUIRED
    logOut: propTypes.func.isRequired,

    //ARRAYS REQUIRED
    token: propTypes.object.isRequired,
    isAuthenticated:propTypes.bool.isRequired,
    
  };
  
  const mapStateToProps = state => ({
    user_token: state.token.user_token,
    isAuthenticated: state.token.isAuthenticated
  });
  
  export default connect(
    mapStateToProps,
    { logOut }
  )(TimeOut);