// URL
// export const PISC_URL = 'http://localhost:5000/pisc-api';
// export const SETTING_URL = 'http://localhost:5001/settings-api';
// export const USERS_URL = 'http://localhost:5002/user-api';
// export const BOARD_URL = 'http://localhost:5003/bms-api';
// export const FARS_URL = 'http://localhost:5004/fars-api';
// export const REPORT_URL = 'http://localhost:5006/report-api';
// export const PISC_SHARE_URL = 'http://localhost:5007/pisc-share-api';
// export const GovESB_URL = 'https://196.192.79.62:5005/govesb';

  //SSL
  export const PISC_URL = '//otrmis.tro.go.tz:5000/pisc-api';
  export const SETTING_URL = '//otrmis.tro.go.tz:5001/settings-api';
  export const USERS_URL = '//otrmis.tro.go.tz:5002/user-api';
  export const BOARD_URL = '//otrmis.tro.go.tz:5003/bms-api';
  export const FARS_URL = '//otrmis.tro.go.tz:5004/fars-api';
  export const GovESB_URL = '//otrmis.tro.go.tz:5005/govesb';
  export const REPORT_URL = '//otrmis.tro.go.tz:5006/report-api';
  export const PISC_SHARE_URL = '//otrmis.tro.go.tz:5007/pisc-share-api';
  export const NTR_URL = '//otrmis.tro.go.tz:5008/ntr-api';

  // export const PISC_URL = '//196.192.79.62:5000/pisc-api';
  // export const SETTING_URL = '//196.192.79.62:5001/settings-api';
  // export const USERS_URL = '//196.192.79.62:5002/user-api';
  // export const BOARD_URL = '//196.192.79.62:5003/bms-api';
  // export const FARS_URL = '//196.192.79.62:5004/fars-api';
  // export const GovESB_URL = '//196.192.79.62:5005/govesb';
  // export const REPORT_URL = '//196.192.79.62:5006/report-api';
  // export const PISC_SHARE_URL = '//196.192.79.62:5007/pisc-share-api';
  // export const NTR_URL = '//196.192.79.62:5008/ntr-api';

  // export const PISC_URL = 'https://196.192.77.35:5000/pisc-api';
  // export const SETTING_URL = 'https://196.192.77.35:5001/settings-api';
  // export const USERS_URL = 'https://196.192.77.35:5002/user-api';
  // export const BOARD_URL = 'https://196.192.77.35:5003/bms-api';
  // export const FARS_URL = 'https://196.192.77.35:5004/fars-api';
  // export const GovESB_URL = 'https://196.192.77.35:5005/govesb';
  // export const REPORT_URL = 'https://196.192.77.35:5006/report-api';
  // export const PISC_SHARE_URL = 'https://196.192.77.35:5007/pisc-share-api';




 