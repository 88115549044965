export const FETCH_POSTS = "FETCH_POSTS";
export const FETCH_MEMBERS = "FETCH_MEMBERS";

export const DQR = "DQR";
export const DATOR = "DATOR";
export const DDER = "DDER";
export const DDAR = "DDAR";
export const DCR = "DCR";
export const DCRR = "DCRR";
export const DSCR = "DSCR";
export const DRoA = "DRoA";
export const DRoE = "DRoE";
export const DRoC = "DRoC"
export const DWCTOR = "DWCTOR";
export const DWCRA = "DWCRA";
export const DNPMR = "DNPMR";
export const DOPMR = "DOPMR";
export const DFY = "DFY";
