import {
  FETCH_DEPARTMENTS,
  NEW_DEPARTMENT,
  UPDATE_DEPARTMENT,
  DELETE_DEPARTMENT
} from "../../actions/types";

const initialState = {
  items: [],
  item: {},
  itemUpdated: {},
  itemDel: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DEPARTMENTS:
      return {
        ...state,
        items: action.payload
      };

    case NEW_DEPARTMENT:
      return {
        ...state,
        item: action.payload
      };

    case UPDATE_DEPARTMENT:
      return {
        ...state,
        itemUpdated: action.payload
      };

    case DELETE_DEPARTMENT:
      return {
        ...state,
        itemDel: action.payload
      };

    default:
      return state;
  }
}
