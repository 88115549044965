import {
  FETCH_FOLDERS,
  NEW_FOLDER,
  UPDATE_FOLDER,
  DELETE_FOLDER
} from "../../actions/types";

const initialState = {
  items: [],
  item: {},
  itemUpdated: {},
  itemDel: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_FOLDERS:
      return {
        ...state,
        items: action.payload
      };

    case NEW_FOLDER:
      return {
        ...state,
        item: action.payload
      };

    case UPDATE_FOLDER:
      return {
        ...state,
        itemUpdated: action.payload
      };

    case DELETE_FOLDER:
      return {
        ...state,
        itemDel: action.payload
      };

    default:
      return state;
  }
}
