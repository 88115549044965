import { ALL_APPOINTMENTS } from "../../actions/types";

const initialState = {
  members: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ALL_APPOINTMENTS:
      return {
        ...state,
        members: action.payload
      };

    default:
      return state;
  }
}