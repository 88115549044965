
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from './auth';
import propTypes from "prop-types";
import { connect } from "react-redux";


const ProtectedRoute = ({component: Component, token, isAuthenticated, ...rest}) => {
    console.log(token);
    return (
        <Route
            {...rest}
            render={props => {
                if(token != null && isAuthenticated) {
                    return (
                        <Component {...props} />
                    )
          
                }else{
                    return ( 
                        <Redirect 
                            to={{
                                pathname: "/",
                                state: {
                                    from: props.location
                                }
                             }}
                        />
                    );  
                }
            }}
        />
    );
}

  const mapStateToProps = state => ({
    token: state.token.userData,
    isAuthenticated: state.token.isAuthenticated
  });


  export default connect(mapStateToProps)(ProtectedRoute);
  
//  export ProtectedRoute;
//   connect(
//     mapStateToProps,
//     { }
//   )(
  //ProtectedRoute;
  //);