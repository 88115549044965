import { combineReducers } from "redux";

import settingsReducer from "./settings.reducer.js";
import themesReducer from "./themes.reducers.js";
import sectorReducer from "./setups/sectorReducer";
import positionReducer from "./setups/positionReducer";
import boardTypeReducer from "./setups/boardTypeReducer";
import clusterReducer from "./setups/clusterReducer.js";
import appointmentTypesReducer from "./setups/appointmentTypesReducer.js";
import capitalReducer from "./setups/capitalReducer.js";
import operationNatureReducer from "./setups/operationNatureReducer.js";
import regulatorReducer from "./setups/regulatorReducer.js";
import subClusterReducer from "./setups/subClusterReducer";
import ministriesReducer from "./setups/ministriesReducer.js";
import ownershipTypeReducer from "./setups/ownershipTypeReducer";

import reportingPeriodReducer from "./setups/reportingPeriodReducer";
import reportingTemplateReducer from "./setups/reportingTemplateReducer";
import quarterReducer from "./setups/quarterReducer";
import quarterMonthReducer from "./setups/quarterMonthReducer";
import regionReducer from "./setups/regionReducer";
import districtReducer from "./setups/districtReducer";

import directorateReducer from "./piscs/directorateReducer";
import departmentReducer from "./piscs/departmentReducer";

import piscsReducer from "./piscs/piscsReducer";
import subsidiariesReducer from "./piscs/subsidiariesReducer";
import equitiesReducer from "./piscs/equitiesReducer";
import associationReducer from "./piscs/associationReducer";
import ceoDirectorReducer from "./piscs/ceoDirectorReducer.js";

import addInstitutionReducer from "./appointment/addInstitutionReducer";
import appointeeReducer from "./appointment/appointeeReducer";
import membersReducer from "./appointment/membersReducer";
import boardCommitteeReducer from "./appointment/boardCommitteeReducer";
import membSecReducer from "./appointment/membSecReducer";
import membSecPiscReducer from "./appointment/membSecPiscReducer";
import appointmentReducer from "./appointment/appointmentReducer";
import lessRequired from "./appointment/lessRequiredReducer";
import noBoardReducer from "./appointment/noBoardReducer";
import inActiveBoardReducer from "./appointment/inActiveBoardReducer";
import activeBoardReducer from "./appointment/activeBoardReducer";
import blackListedReducer from "./appointment/blackListedReducer";
import actingCeoReducer from "./appointment/actingCeoReducer.js";
import appointmentDataReducer from "./appointment/appointmentDataReducer";
import committeeAppointmentReducer from "./appointment/committeeAppointmentReducer.js";
import committeeMemberReducer from "./appointment/committeeMemberReducer";
import getMembersReducer from "./appointment/getMembersReducer";
//users
import permissionReducer from "./users/permissionReducer";
import rolesReducer from "./users/rolesReducer";
import rolesPermitReducer from "./users/rolesPermitReducer.js";
import modulesReducer from "./users/modulesReducer.js";
import userReducer from "./users/userReducer.js";
import userModulesReducer from "./users/userModulesReducer";
import loginReducer from "./users/loginReducer.js";
import piscQueryReducer from "./piscs/piscQueryReducer";
import assignSectorReducer from "./users/assignSectorReducer.js";
//PIS & MI Reports
import piscReportsReducer from "./reports/pisc/piscReportsReducer";
import commercialReportsReducer from "./reports/pisc/commercialReportsReducer";
import noncommercialReportsReducer from "./reports/pisc/noncommercialReportsReducer";
import dependentReportsReducer from "./reports/pisc/dependentReportsReducer";
import independentReportsReducer from "./reports/pisc/independentReportsReducer";
import misReportsReducer from "./reports/pisc/misReportsReducer";
import companyReportsReducer from "./reports/pisc/companyReportsReducer";
import equityReportsReducer from "./reports/pisc/equityReportsReducer";
import sharesReducer from './piscs/sharesReducer';
//Documents
import folderReducer from "./documents/folderReducer";
import documentReducer from "./documents/documentReducer";

import committeeReducer from "./reports/committeeReducer";
import representativeReducer from "./reports/representativeReducer";
import ikamaReducer from "./reports/ikamaReducer";
import branchReducer from "./piscs/branchReducer.js";

//fars
import farsDataReducer from "./fars/farsDataReducer";
import farsAnalysisReducer from "./fars/farsAnalysisReducer.js";
import farsRatioReducer from "./fars/farsRatioReducer.js";
import farsRDReducer from "./fars/farsRDReducer.js";
import submissionTrendReducer from "./fars/submissionTrendReducer.js";
import govPositionsReducer from "./setups/govPositionsReducer.js";
import govOfficialsReducer from "./users/govOfficialsReducer.js";
import piscValuesReducer from "./piscs/piscValuesReducer.js";




const appReducer = combineReducers({
  settings: settingsReducer,
  theme: themesReducer,

  //LOGIN
  token: loginReducer,

  //USERS SECTION
  modules: modulesReducer,
  permissions: permissionReducer,
  roles: rolesReducer,
  rolespermit: rolesPermitReducer,
  users: userReducer,
  userModules: userModulesReducer,
  govOfficials: govOfficialsReducer,
  piscQuery: piscQueryReducer,
  assignedSectors: assignSectorReducer,
  farsData: farsDataReducer,
  farsAnalysis: farsAnalysisReducer,
  farsRatio:farsRatioReducer,
  farsRD:farsRDReducer,
  submissionTrend: submissionTrendReducer,

  //APPOINTMENT SECTION
  appointees: appointeeReducer,
  members: membersReducer,
  secMembers: membSecReducer,
  piscMembers: membSecPiscReducer,
  appointments: appointmentReducer,
  lessMembers: lessRequired,
  noBoards: noBoardReducer,
  inactiveBoards: inActiveBoardReducer,
  activeBoards: activeBoardReducer,
  blacklists: blackListedReducer,
  ceo: actingCeoReducer,
  appointmentData: appointmentDataReducer,
  boardCommittees: boardCommitteeReducer,
  committeeAppointments: committeeAppointmentReducer,
  committeeMembers: committeeMemberReducer,
  allMembers:getMembersReducer,
  taasisi: addInstitutionReducer,

  // SETUP SECTION
  appointmentTypes: appointmentTypesReducer,
  capitals: capitalReducer,
  clusters: clusterReducer,
  ministries: ministriesReducer,
  operations: operationNatureReducer,
  ownerships: ownershipTypeReducer,
  positions: positionReducer,
    govPositions: govPositionsReducer,
  boardTypes: boardTypeReducer,
  regulators: regulatorReducer,
  reportingperiods: reportingPeriodReducer,
  sectors: sectorReducer,
  subclusters: subClusterReducer,
  reportingtemplates: reportingTemplateReducer,
  quarters: quarterReducer,
  quartermonths: quarterMonthReducer,
  regions: regionReducer,
  districts: districtReducer,
  //PISC
  piscs: piscsReducer,
  subsidiaries: subsidiariesReducer,
  shares: sharesReducer,
  branches: branchReducer,
  // equities: equityReducer,
  associations: associationReducer,
  ceoDirectors: ceoDirectorReducer,
  directorates: directorateReducer,
  departments: departmentReducer,
  //PISC & MI REPORTS
  piscReports: piscReportsReducer,
  commercialReports: commercialReportsReducer,
  noncommercialReports: noncommercialReportsReducer,
  dependentReports: dependentReportsReducer,
  independentReports: independentReportsReducer,
  miReports: misReportsReducer,
  companyReports: companyReportsReducer,
  equityReports: equityReportsReducer,
  //documents
  documents: documentReducer,
  folders: folderReducer,
  committeeReports: committeeReducer,
  representativeReports: representativeReducer,
  ikamaReports: ikamaReducer,
  
  //dPISC ID
  shareid: piscValuesReducer
});

export const rootReducer = (state, action) => {
  if (action.type === "AUTH_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};
