export const FETCH_POSTS = "FETCH_POSTS";
export const NEW_POST = "NEW_POST";
export const UPDATE_POST = "UPDATE_POST";
export const DELETE_POST = "DELETE_POST";
export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const FETCH_MEMBERS1 = "FETCH_MEMBERS1";
export const FETCH_MEMBERS2 = "FETCH_MEMBERS2";
export const FETCH_APPOINTMENTS = "FETCH_APPOINTMENTS";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
//piscs
export const FETCH_PISCS = "FETCH_PISCS";
export const NEW_PISC = "NEW_PISC";
export const UPDATE_PISC = "UPDATE_PISC";
export const DELETE_PISC = "DELETE_PISC";
//pisc & MI Reports
export const PISC_POSTS = "PISC_POSTS";
export const COMMERCIAL_PISCS = "COMMERCIAL_PISCS";
export const NON_COMMERCIAL_PISCS = "NON_COMMERCIAL_PISCS";
export const DEPENDENT_PISCS = "DEPENDENT_PISCS";
export const INDEPENDENT_PISCS = "INDEPENDENT_PISCS";
export const FILTER_MIS = "FILTER_MIS";
export const MIS_LIST = "MIS_LIST";
export const SUBSIDIARY_REPORTS = "SUBSIDIARY_REPORTS";
export const PISC_REPORTS = "PISC_REPORTS";
export const COMPANIES_LIST = "COMPANIES_LIST";
export const EQUITY_COMPANIES = "EQUITY_COMPANIES";
export const FETCH_MEMBERS3 = "FETCH_MEMBERS3";

//department
export const FETCH_DEPARTMENTS = "FETCH_DEPARTMENTS";
export const NEW_DEPARTMENT = "NEW_DEPARTMENT";
export const UPDATE_DEPARTMENT = "UPDATE_DEPARTMENT";
export const DELETE_DEPARTMENT = "DELETE_DEPARTMENT";

//committee appointments
export const COMMITTEE_APPOINTMENTS = "COMMITTEE_APPOINTMENTS";
export const NEW_COMMITTEE_APPOINTMENT = "NEW_COMMITTEE_APPOINTMENT";
export const UPDATE_COMMITTEE_APPOINTMENT = "UPDATE_COMMITTEE_APPOINTMENT";
export const DELETE_COMMITTEE_APPOINTMENT = "UPDATE_COMMITTEE_APPOINTMENT";
export const ALL_APPOINTMENTS = "ALL_APPOINTMENTS";

//committee appointments
export const FETCH_COMMITTEE_MEMBERS = "FETCH_COMMITTEE_MEMBERS";
export const NEW_COMMITTEE_MEMBER = "NEW_COMMITTEE_MEMBER";
export const UPDATE_COMMITTEE_MEMBER = "UPDATE_COMMITTEE_MEMBER";
export const DELETE_COMMITTEE_MEMBER = "DELETE_COMMITTEE_MEMBER";

//documents
export const FETCH_FOLDERS = "FETCH_FOLDERS";
export const NEW_FOLDER = "NEW_FOLDER";
export const UPDATE_FOLDER = "UPDATE_FOLDER";
export const DELETE_FOLDER = "DELETE_FOLDER";

export const FETCH_UPLOADS = "FETCH_UPLOADS";
export const NEW_UPLOAD = "NEW_UPLOAD";
export const UPDATE_UPLOAD = "UPDATE_UPLOAD";
export const DELETE_UPLOAD = "DELETE_UPLOAD";

export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const NEW_DOCUMENT = "NEW_DOCUMENT";
export const UPDATE_DOCUMENT = "UPDATE_DOCUMENT";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";

//appointee
export const FETCH_APPOINTEES = "FETCH_APPOINTEES";
export const NEW_APPOINTEE = "NEW_APPOINTEE";
export const UPDATE_APPOINTEE = "UPDATE_APPOINTEE";
export const DELETE_APPOINTEE = "DELETE_APPOINTEE";

export const REPRESENTATIVE_REPORTS = "REPRESENTATIVE_REPORTS";
export const COMMITTEE_REPORTS = "COMMITTEE_REPORTS";
export const DEPARTMENT_REPORTS = "DEPARTMENT_REPORTS";

//regions
export const FETCH_REGIONS ="DELETE_REGION";
export const NEW_REGION = "DELETE_REGION";
export const UPDATE_REGION = "DELETE_REGION";
export const DELETE_REGION = "DELETE_REGION";

//districts
export const FETCH_DISTRICTS ="DELETE_DISTRICT";
export const NEW_DISTRICT = "DELETE_DISTRICT";
export const UPDATE_DISTRICT = "DELETE_DISTRICT";
export const DELETE_DISTRICT = "DELETE_DISTRICT";

export const FETCH_SHARES = "FETCH_SHARES";

export const FETCH_BRANCHES = "FETCH_BRANCHES";
export const NEW_BRANCH = "NEW_BRANCH"; 
export const UPDATE_BRANCH = "UPDATE_BRANCH"; 
export const DELETE_BRANCH =  "DELETE_BRANCH";

//INSTITUTIONS
export const FETCH_INSTITUTIONS = "FETCH_INSTITUTIONS";


//GET ID
export const GET_ID = "GET_ID";
export const SET_ID = "SET_ID";