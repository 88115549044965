import {
    FETCH_POSTS,
    FETCH_MEMBERS,
    FETCH_MEMBERS1,
    FETCH_MEMBERS2,
    FETCH_MEMBERS3
  } from "../../actions/types";
  const initialState = {
    activeBoards:[],
    inactiveBoards:[],
    noBoard:[],
    incompleteBoard:[],
    lessBoards:[]
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case FETCH_POSTS:
        return {
          ...state,
          activeBoards: action.payload
        };
  
      case FETCH_MEMBERS:
        return {
          ...state,
          inactiveBoards: action.payload
        };
  
      case FETCH_MEMBERS1:
        return {
          ...state,
          lessBoards: action.payload
        };
  
      case FETCH_MEMBERS2:
        return {
          ...state,
          incompleteBoard: action.payload
        };

      case FETCH_MEMBERS3:
        return {
          ...state,
          noBoard: action.payload
        };
  
      default:
        return state;
    }
  }
  