import { WCTO, WC, NPM, OPM, TRev, TExp } from "../../actions/fars/typefars";

const initialState = {
    WCTO : 0,
    WC : 0,
    NPM : 0,  
    OPM : 0,   
    TRev : 0,
    TExp : 0  
};

export default function(state = initialState, action) {
  switch (action.type) {
    case WCTO:
      return {
            ...state,
            WCTO: action.payload
      };

      case WC:
        return {
            ...state,
            WC: action.payload
        };

    case NPM:
        return {
            ...state,
            NPM: action.payload
        };
    
    case OPM:
        return {
            ...state,
            OPM: action.payload
        };

    case TRev:
        return {
            ...state,
            TRev: action.payload
        };
        
    case TExp:
        return {
            ...state,
            TExp: action.payload
        };
    
    default:
      return state;
  }
}


