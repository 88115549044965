import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';

class SidebarUserBlock extends Component {

    state = {
        showUserBlock: false
    }

    componentWillReceiveProps(newProps) {
        if (newProps.showUserBlock !== this.props.showUserBlock) {
            this.setState({ showUserBlock: newProps.showUserBlock })
        }
    }

    render() {
        return (
            <Collapse id="user-block" isOpen={ this.state.showUserBlock }>
                <div>
                    <div className="item user-block">
                       {/* User picture */}
                       <div className="user-block-picture">
                          <div className="user-block-status">
                             <Link to="/user_profile">
                             <img className="img-thumbnail rounded-circle" src="img/user_1.png" alt="Avatar" width="60" height="60" />
                            </Link>
                             <div className="circle bg-success circle-lg"></div>
                          </div>
                       </div>
                       {/* Name and Job */}
                       <div className="user-block-info">
                          
                            {this.props.token != null && this.props.token.length > 0 ? (
                                <>
                                    <span className="user-block-name">
                                        {this.props.token[0].user_first_name + " " + this.props.token[0].user_last_name} 
                                    </span>
                                    <span className="user-block-role">{this.props.token[0].role_name}</span>
                                </>
                                
                            ) : ''}
                             
                          
                       </div>
                    </div>
                </div>
            </Collapse>
        )
    }
}

SidebarUserBlock.propTypes = {
    showUserBlock: PropTypes.bool,
    token: PropTypes.array.isRequired
};

const mapStateToProps = state => ({ 
    showUserBlock: state.settings.showUserBlock,
    token: state.token.userData
 })

export default connect(
    mapStateToProps
)(SidebarUserBlock);