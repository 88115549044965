import { FETCH_POSTS, NEW_POST, UPDATE_POST, DELETE_POST, FETCH_MEMBERS } from "../../actions/types";

const initialState = {
  items: [],
  //roleItem:[],
  item: {},
  itemUpdated:{},
  itemDel:{},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_POSTS:
      return {
        ...state,
        items: action.payload
      };

    case NEW_POST:
      return {
        ...state,
        item: action.payload
      };

    case UPDATE_POST:
      return {
        ...state,
        itemUpdated: action.payload
      };

    case DELETE_POST:
      return {
        ...state,
        itemDel: action.payload
      };

    default:
      return state;
  }
}
