import {
  FETCH_PISCS,
  NEW_PISC,
  UPDATE_PISC,
  DELETE_PISC
} from "../../actions/types";
const initialState = {
  items: [],
  item: {},
  itemUpdated: {},
  itemDel: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_PISCS:
      return {
        ...state,
        items: action.payload
      };

    case NEW_PISC:
      return {
        ...state,
        item: action.payload
      };

    case UPDATE_PISC:
      return {
        ...state,
        itemUpdated: action.payload
      };

    case DELETE_PISC:
      return {
        ...state,
        itemDel: action.payload
      };

    default:
      return state;
  }
}
