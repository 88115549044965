/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.2.2
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component } from "react";
import { BrowserRouter } from "react-router-dom";

// App Routes
import Routes from "./Routes";

// Vendor dependencies
import "./Vendor";
// Application Styles
import "./styles/bootstrap.scss";
import "./styles/app.scss";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./styles/otr/css/style.css";
import "./styles/otr/css/login.css";

class App extends Component {
  render() {
    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    // const basename =
    //   process.env.NODE_ENV === "development" ? "/" : PUBLIC_URL || "/";
    
    // caches.keys().then((names) => {
    //   names.forEach((name) => {
    //     caches.delete(name);
    //   });
    // });

    const basename =
      process.env.NODE_ENV === "development" ? "/" : 'http://otrmis.tro.go.tz:80' || "/*";


    return (
      <BrowserRouter basename={basename}>
        <Routes />
      </BrowserRouter>
    );
  }
}

export default App;
