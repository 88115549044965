import {
    FETCH_INSTITUTIONS
  } from "../../actions/types";
  
  const initialState = { items: []  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case FETCH_INSTITUTIONS:
        return {
          ...state,
          items: action.payload
        };
  
      default:
        return state;
    }
  }
  