import {
  GET_ID, SET_ID } from "../../actions/types";
const initialState = {
  id: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ID:
      return {
        ...state,
        id: action.payload
      };

    case SET_ID:
      return {
        ...state,
        id: action.payload
      };

    default:
      return state;
  }
}
