import { QR, ATOR, DER, DAR, CR, CRR, SCR, WCTOR, WCRA, NPMR, OPMR, RoA, RoE, RoC, FY } from "../../actions/fars/typefars";

const initialState = {
    QR : [],
    ASTOR : [],
    DER : [],  
    DAR : [],   
    CR : [],
    CCR : [],
    SCR : [],
    WCTOR : [],
    WCRA : [],
    NPMR : [],
    OPMR : [],
    RoA : [],
    RoE : [],
    RoC : [],
    FYS : []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case QR:
      return {
            ...state,
            QR: action.payload
      };

      case ATOR:
        return {
            ...state,
            ASTOR: action.payload
        };

    case DER:
        return {
            ...state,
            DER: action.payload
        };
    
    case DAR:
        return {
            ...state,
            DAR: action.payload
        };

    case CR:
        return {
            ...state,
            CR: action.payload
        };
        
    case CRR:
        return {
            ...state,
            CCR: action.payload
        };

    case SCR:
        return {
            ...state,
            SCR: action.payload
        };

    case WCTOR:
        return {
            ...state,
            WCTOR: action.payload
        };

    case WCRA:
        return {
            ...state,
            WCRA: action.payload
        };

    case NPMR:
        return {
            ...state,
            NPMR: action.payload
        };
    
    case OPMR:
        return {
            ...state,
            OPMR: action.payload
        };

    case RoA:
        return {
            ...state,
            RoA: action.payload
        };

    case RoE:
        return {
            ...state,
            RoE: action.payload
        };

    case RoC:
        return {
            ...state,
            RoC: action.payload
        };

    case FY:
        return {
            ...state,
            FYS: action.payload
        };

    default:
      return state;
  }
}






