import { FETCH_DISTRICTS, NEW_DISTRICT, UPDATE_DISTRICT, DELETE_DISTRICT } from "../../actions/types";

const initialState = {
  items: [],
  item: {},
  itemUpdated:{},
  itemDel:{},
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_DISTRICTS:
      return {
        ...state,
        items: action.payload
      };

    case NEW_DISTRICT:
      return {
        ...state,
        item: action.payload
      };

    case UPDATE_DISTRICT:
      return {
        ...state,
        itemUpdated: action.payload
      };

    case DELETE_DISTRICT:
      return {
        ...state,
        itemDel: action.payload
      };

    default:
      return state;
  }
}
